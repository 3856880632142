import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo"
import CTA from "../components/cta";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import CustomIntegrationIcon from "../icons/custom-integration.svg"
import IntegrationIcon from "../icons/integration.svg"
import UiUxIcon from "../icons/ui-ux-setup.svg"
import WebsiteDesignIcon from "../icons/website-design.svg"

const ZendeskAdmin = ({location}) => {
return (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Admin"
      description="You don’t have to hire a full-time employee with 729 Solutions’ Zendesk admin services."
    />
    <section>
      <div
        className="hero mb-5"
        style={{
          backgroundImage: `url(${patternHex})`,
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px" }}
            />
          </div>
          <h1 className="text-white">Need a Zendesk admin?</h1>
          <p className="text-white">
            You don’t have to hire a full-time employee with 729 Solutions’
            Zendesk admin services.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mr-auto">
            <div>
              <h2>Why use a 729 admin?</h2>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    Control your spend and lower your customer support budget
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Bring best practices and expertise to your customer success
                    organization
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Save time to start – 729 can kick off work as soon as you
                    sign
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    No need to invest the time and money in training, we have
                    Certified Zendesk Admins ready to go
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Only pay for what you use by the hour and only when needed
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Identify problems before they become issues
                  </span>
                </li>
              </ul>
            </div>

            <div className="pt-6">
              <h2>How does it work?</h2>
              <p>
                You can buy admin work by the hour on a monthly retainer basis.
                We help you on a weekly or monthly basis to optimize your
                Zendesk use. You get direct access to your admin for questions,
                to make changes or to help you do customer support projects. All
                work is delivered remotely. We can have any communication
                cadence you would like. Your admin will stay with you from month
                to month so you can trust them to learn about your business and
                help you grow.
              </p>
            </div>
          </div>

          <div className="pt-6 pt-lg-0 col-lg-4">
            <h2>What can my admin do?</h2>
            <ul className="text-primary">
              <li>
                <span className="text-dark">
                  <strong>Create</strong> and <strong>edit</strong>{" "}
                  automation/macros
                </span>
              </li>
              <li>
                <span className="text-dark">
                  <strong>Design</strong> and <strong>implement</strong> the
                  ticket workflow
                </span>
              </li>
              <li>
                <span className="text-dark">
                  <strong>Define</strong> your unique business rules in the tool
                </span>
              </li>
              <li>
                <span className="text-dark">
                  <strong>Improve</strong> your guide content and user
                  experience
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Help <strong>build out</strong> an agent facing portal
                </span>
              </li>
              <li>
                <span className="text-dark">
                  <strong>Monitor</strong> user content
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Help create <strong>training</strong> documentation for agents
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Improve <strong>workflows</strong>
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Create <strong>custom reports</strong>
                </span>
              </li>
              <li>
                <span className="text-dark">
                  <strong>Create and track</strong> SLA service targets
                </span>
              </li>
              <li>
                <span className="text-dark">
                  <strong>Onboard</strong>, <strong>offboard</strong>, and{" "}
                  <strong>manage</strong> agents
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Create and <strong>improve agent views</strong>
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Manage <strong>triggers</strong>
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Add new channels like <strong>chat</strong> or{" "}
                  <strong>talk</strong>
                </span>
              </li>
              <li>
                <span className="text-dark">
                  Install and <strong>configure</strong> the app
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="pt-6">
      <div className="bg-dark pt-5 pb-1">
        <div className="container">
          <h2 className="text-white">
            Optimize your team’s use of Zendesk with the help of an experienced
            Zendesk Admin
          </h2>
        </div>
      </div>
    </section>

    <section className="pt-6">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-left">
              <h2>What service level will i get?</h2>
              <div className="row">
                <div className="col-12 col-md-6">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        2 hour response time during Pacific business hours 9am-5pm
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Access to a joint Slack Channel for communication
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        A weekly report on work that is done and hours used
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Time is billed in ¼ hour increments so no time is wasted!
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            <div className="text-center">
              <CTA
                href="/contact-us/"
                text="I WOULD LIKE TO LEARN MORE"
                className="btn btn-primary mt-4"
              />
            </div>  
          </div>
        </div>
      </div>
        <h2 className="pt-6">Your admin can also help:</h2>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <ul className="text-primary">
              <li>
                <span className="text-dark">Configure Zendesk Support</span>
              </li>
              <li>
                <span className="text-dark">Configure Zendesk Explore</span>
              </li>
              <li>
                <span className="text-dark">Configure Zendesk Talk</span>
              </li>
              <li>
                <span className="text-dark">Configure Zendesk Chat</span>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <ul className="text-primary">
              <li>
                <span className="text-dark">Configure Zendesk Sell</span>
              </li>
              <li>
                <span className="text-dark">Configure Zendesk Guide</span>
              </li>
              <li>
                <span className="text-dark">
                  Install and configure Zendesk Marketplace Apps
                </span>
              </li>
              <li>
                <span className="text-dark">Provide Guidance</span>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <ul className="text-primary">
              <li>
                <span className="text-dark">Implement new workflows</span>
              </li>
              <li>
                <span className="text-dark">Diagnose issues</span>
              </li>
              <li>
                <span className="text-dark">
                  Provide suggestions for improvements
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="pt-6">
      <div className="container">
        <h2>
          If you need more than admin work, 729 can help with any Zendesk jobs!
        </h2>
        <p>
          If you need full design and development, 729 has a full professional
          services team for Zendesk that can help with the following:
        </p>
        <div className="row pt-4">
          <div className="col-12 col-md-6 col-lg-3">
            <CustomIntegrationIcon width={144} alt="Custom Integration" />
            <p className="pt-3">
              Write <span className="text-dark">custom code</span> for Zendesk
            </p>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <IntegrationIcon width={144} alt="Integrations" />
            <p className="pt-3">
              <span className="text-dark">Integrate</span> any tools/platforms
              outside of Zendesk
            </p>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <UiUxIcon width={144} alt="Custom Applications" />
            <p className="pt-3">
              Write <span className="text-dark">custom applications</span> for
              Zendesk
            </p>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <WebsiteDesignIcon width={144} alt="Design" />
            <p className="pt-3">
              Provide <span className="text-dark">design</span> or{" "}
              <span className="text-dark">layout guidance</span> for Zendesk
              Guide
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
};

export default ZendeskAdmin;